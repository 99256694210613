import moment from "moment";

export const getHoursAndMinFromMillis = (mills, isDays, getHrs) => {
  if (mills) {
    const seconds = mills / 1000;
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    const istTime = convertToIST(new Date(mills));

    const istTimeParts = istTime.split(":");
    const istHours = parseInt(istTimeParts[0], 10);
    const istMinutes = parseInt(istTimeParts[1], 10);
    const istAmPm = istHours >= 12 ? "PM" : "AM";
    const istHours12 = istHours > 12 ? istHours - 12 : istHours;

    const istDate = new Date(mills + 5.5 * 60 * 60 * 1000); // Add IST offset
    const istDay = istDate.toLocaleDateString("en-IN", {
      weekday: "long",
    });

    if (getHrs) {
      return `${istHours12}`;
    }
    if (isDays) {
      return `${istDay}`;
    } else {
      return `${istHours12}:${istMinutes} ${istAmPm}`;
    }
  } else {
    return "- -";
  }
};

function convertToIST(date) {
  const ISTOffset = 5.5 * 60 * 60 * 1000; // 5 hours 30 minutes in milliseconds
  const utcTime = date.getTime() + date.getTimezoneOffset() * 60000;
  const istTime = utcTime + ISTOffset;
  const istDate = new Date(istTime);

  const istTimeString = istDate.toLocaleTimeString("en-IN", {
    timeZone: "Asia/Kolkata",
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  return istTimeString;
}

export const getDaysArray = function (s, e) {
  for (
    var a = [], d = new Date(s);
    d <= new Date(e);
    d.setDate(d.getDate() + 1)
  ) {
    let date = moment(new Date(d)).format("YYYY-MM-DD");
    a.push(date);
  }
  return a;
};
