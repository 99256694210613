import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import cx from "classnames";
import jwt from "jsonwebtoken";
import "./sideMenu.scss";

class SideMenu extends Component {
  state = {
    active: 0,
    logout: false,
  };

  hideSideBarOnClickMobile = () => {
    const { toggleSideMenu } = this.props;
    if (typeof toggleSideMenu === "function") {
      return toggleSideMenu();
    }
  };

  handleLinkClick = (activeItemIndex) => {
    this.setState({ active: activeItemIndex }, this.hideSideBarOnClickMobile);
  };

  routeChange = (path) => {
    // window.history.push(path);
  };

  deleteToken = () => {
    // window.history.push("login");
    localStorage.removeItem("idToken");
    localStorage.removeItem("refreshToken");
  };

  render() {
    const { active } = this.state;

    const activeLink = Math.round(this.props.active);
    const activeItem = active || activeLink;
    const activeItemClass = "menuItem active";
    const defaultItemClass = "menuItem";

    const token = localStorage.getItem("idToken");
    const decodedToken = jwt.decode(token);

    return (
      <>
        <div id="SideNav">
          {/* <div className="logo d-none d-md-block">
            <Link to="/">
              <img
                className="ml-3 mb-3 zingBusLogo"
                style={{ width: "50%" }}
                src="https://d1flzashw70bti.cloudfront.net/original/images/operator/desktop/logo.svg"
                alt="zingbus Logo"
              />
            </Link>
          </div> */}
          <div className="menu">
            <div
              className={cx(
                activeItem === 1 ? activeItemClass : defaultItemClass
              )}
            >
              <span className="menuIcon">
                <img
                  src="https://d1flzashw70bti.cloudfront.net/original/images/operato/navbar/home.svg"
                  alt="menu icon"
                />
              </span>
              <Link
                to="/"
                className="menuItemFont"
                onClick={() => this.handleLinkClick(1)}
              >
                Home
              </Link>
            </div>
            <div
              className={cx(
                activeItem === 2 ? activeItemClass : defaultItemClass
              )}
            >
              <span className="menuIcon">
                <img
                  src="https://d1flzashw70bti.cloudfront.net/original/images/operator/navbar/ticker.svg"
                  alt="menu icon"
                />
              </span>
              <Link
                to="/inspectionDataDetails"
                className="menuItemFont"
                onClick={() => this.handleLinkClick(1)}
              >
                Inspection Data
              </Link>
            </div>
            <div
              className={cx(
                activeItem === 4 ? activeItemClass : defaultItemClass
              )}
            >
              <span className="menuIcon">
                <img
                  src="https://d1flzashw70bti.cloudfront.net/original/images/operator/navbar/ticker.svg"
                  alt="menu icon"
                />
              </span>
              <Link
                to="/auditDataDetails"
                className="menuItemFont"
                onClick={() => this.handleLinkClick(1)}
              >
                Audit Data
              </Link>
            </div>
            <div
              className={cx(
                activeItem === 5 ? activeItemClass : defaultItemClass
              )}
            >
              <span className="menuIcon">
                <img
                  src="https://d1flzashw70bti.cloudfront.net/original/images/operator/navbar/ticker.svg"
                  alt="menu icon"
                />
              </span>
              <Link
                to="/FeedbackDetails"
                className="menuItemFont"
                onClick={() => this.handleLinkClick(1)}
              >
                Feedback
              </Link>
            </div>
            <div
              className={cx(
                activeItem === 3 ? activeItemClass : defaultItemClass
              )}
            >
              <span className="menuIcon">
                <img
                  src="https://d1flzashw70bti.cloudfront.net/original/images/operator/tab/user.svg"
                  alt="profile icon"
                />
              </span>
              <Link
                to="/profile"
                className="menuItemFont"
                onClick={() => this.handleLinkClick(1)}
              >
                Profile
              </Link>
            </div>
          </div>

          <div className="bottomDetails">
            <Link
              to="/login"
              className="menuItemFont"
              onClick={this.deleteToken}
            >
              <button className="confirmButton">
                <img
                  src="https://d1flzashw70bti.cloudfront.net/original/images/operator/logout.svg"
                  alt="logout"
                  className="logoutImage"
                ></img>
                <span className="text">Logout Session</span>
              </button>
            </Link>
          </div>
        </div>
      </>
    );
  }
}

export default SideMenu;
