import React, { Component } from "react";
import "./inspectionDataMoreInfoTile.scss";

class InspectionDataMoreInfoTile extends Component {
  render() {
    const { serial, action, value, remark, attachments, showAttachment } =
      this.props;

    return (
      <div className="inspectionDataMoreInfoTile">
        <div className="heading">
          <span>{`Serial Number: ${serial}`}</span>
        </div>
        <div className="subSection">
          <div className="subHeading">
            <span>Action Performed</span>
            <p>{action}</p>
          </div>
          <div className="subHeading">
            <span>Value</span>
            <p>{value}</p>
          </div>
          <div className="subHeading">
            <span>Remarks</span>
            <p>{remark}</p>
          </div>
        </div>
        <div className="options">
          {attachments &&
            attachments.length > 0 &&
            attachments.map((data, index) => {
              if (!data) return null;

              return data.attachmentType === "video" ? (
                <div
                  key={index}
                  className="option"
                  onClick={() =>
                    showAttachment(data.attachmentUrl, data.attachmentType)
                  }
                >
                  <img
                    src="https://d1flzashw70bti.cloudfront.net/original/images/operator/inspection/video.svg"
                    alt="Video Icon"
                  />
                  <a href="#">{data.attachmentLabel}</a>
                </div>
              ) : data.attachmentType === "image" ? (
                <div
                  key={index}
                  className="option"
                  onClick={() =>
                    showAttachment(data.attachmentUrl, data.attachmentType)
                  }
                >
                  <img
                    src="https://d1flzashw70bti.cloudfront.net/original/images/operator/imgIcon.svg"
                    alt="Camera Icon"
                  />
                  <a href="#">{data.attachmentLabel}</a>
                </div>
              ) : data.attachmentType === "audio" ? (
                <div
                  key={index}
                  className="option"
                  onClick={() =>
                    showAttachment(data.attachmentUrl, data.attachmentType)
                  }
                >
                  <img
                    src="https://d1flzashw70bti.cloudfront.net/original/images/operator/imgIcon.svg"
                    alt="Audio Icon"
                  />
                  <a href="#">{data.attachmentLabel}</a>
                </div>
              ) : null;
            })}
        </div>
      </div>
    );
  }
}

export default InspectionDataMoreInfoTile;
