import React, { Component } from "react";
import "./inspectionDataTile.scss";

class InspectionDataTile extends Component {
  render() {
    const { id, busRc, completionTime, completionDate, onButtonClick } =
      this.props;

    return (
      <div className="tileContainer" key={busRc}>
        <div className="tileRow">
          <span className="busRcText">{busRc}</span>
          <button className="tileButtonRight" onClick={() => onButtonClick(id)}>
            View
          </button>
        </div>
        <div className="separator"></div>
        <div className="tileRow">
          <span className="tileTextLeft">Completion Time:</span>
          <span className="tileTextRight">{completionTime}</span>
        </div>
        <div className="tileRow">
          <span className="tileTextLeft">Completion Date:</span>
          <span className="tileTextRight">{completionDate}</span>
        </div>
      </div>
    );
  }
}

export default InspectionDataTile;
