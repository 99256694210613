import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, ConfigProvider } from "antd";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 50,
    }}
    spin
  />
);

const Loader = () => (
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: "#7b2bff",
      },
    }}
  >
    <Spin indicator={antIcon} />
  </ConfigProvider>
);
export default Loader;
