import React, { Component } from "react";
import "./style.scss";

class FeedbackData extends Component {
  render() {
    const { id, name, index, date, ratings, onButtonClick } = this.props;

    return (
      <div className="tileContainer" key={index}>
        <div className="tileRow">
          <span className="busRcText">{name}</span>
          <button className="tileButtonRight" onClick={() => onButtonClick(id)}>
            View
          </button>
        </div>
        <div className="separator"></div>
        <div className="tileRow">
          <span className="tileTextLeft">Avg. Ratings:</span>
          <span className="tileTextRight">{ratings}</span>
        </div>
        <div className="tileRow">
          <span className="tileTextLeft">Date:</span>
          <span className="tileTextRight">{date}</span>
        </div>
      </div>
    );
  }
}

export default FeedbackData;
