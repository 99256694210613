const devServerURL = "https://operator.zingbus.com/v1";
export let baseURL = devServerURL;

export const inspectionOptions = [
  {
    value: "INSPECTIONONE",
    label: "Inspection Data 1",
  },
  {
    value: "INSPECTIONTWO",
    label: "Inspection Data 2",
  },
  {
    value: "INSPECTIONTHREE",
    label: "Inspection Data 3",
  },
  {
    value: "INSPECTIONFOUR",
    label: "Inspection Data 4",
  },
  {
    value: "INSPECTIONFIVE",
    label: "Inspection Data 5",
  },
];

export const AuditOptions = [
  {
    value: "RegularAudit",
    label: "Regular Audit",
  },
  {
    value: "OnboardingAudit",
    label: "Onboarding Audit",
  },
  {
    value: "TripStart Audit",
    label: "TripStart Audit",
  },
];

export const cloundFrontBaseUrl = "https://d370edwo3kkakj.cloudfront.net/";

