import React from "react";
import { Button, message } from "antd";
import "./otp.scss";

class Otpinput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      otp1: "",
      otp2: "",
      otp3: "",
      otp: "",
      otp4: "",
      disable: true,
      timer: 30,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.tick = setInterval(this.onTick, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.tick);
  }

  onTick = () => {
    if (this.state.timer === 0) {
      clearInterval(this.tick);
    } else {
      this.setState({ timer: this.state.timer - 1 });
    }
  };

  handleChange(value1, event) {
    this.setState({ [value1]: event.target.value });
  }

  handleSubmit(event) {
    // const data = new FormData(event.target);
    event.preventDefault();
  }

  inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      let seletectedCol = elmnt.target.tabIndex;
      let otpVal = `otp${seletectedCol}`;
      this.setState({ [otpVal]: "" });
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else if (
      elmnt.key === "0" ||
      elmnt.key === "1" ||
      elmnt.key === "2" ||
      elmnt.key === "3" ||
      elmnt.key === "4" ||
      elmnt.key === "5" ||
      elmnt.key === "6" ||
      elmnt.key === "7" ||
      elmnt.key === "8" ||
      elmnt.key === "9"
    ) {
      const next = elmnt.target.tabIndex;
      if (next < 4) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex - 1;
      elmnt.target.form.elements[next].focus();
    }
  };

  verifyOTP = async () => {
    const { number } = this.props;
    let CalOtp =
      this.state.otp1 + this.state.otp2 + this.state.otp3 + this.state.otp4;
    if (
      (!this.props.isMobile && this.state.otp == "") ||
      (this.props.isMobile && CalOtp == "")
    ) {
      message.error("Please enter OTP");
      return;
    }
    if (this.state.otp) {
      CalOtp = this.state.otp;
    }
    const data = {
      otp: CalOtp,
      mobileNo: number,
    };
    this.props.verifyOTP(data);
  };

  resendOTP = () => {
    this.setState({ timer: 30 });
    this.tick = setInterval(this.onTick, 1000);
    this.props.resendOtp();
  };

  showNumberInput = () => {
    this.props.hideOtp();
  };

  render() {
    const { otp1, otp2, otp3, otp4, timer, otp } = this.state;
    const { number, showOtpError, isMobile } = this.props;
    if (isMobile) {
      return (
        <>
          <form onSubmit={this.handleSubmit}>
            <span className="numberInfo">We have sent an OTP to {number} </span>
            <span className="editInfo" onClick={this.showNumberInput}>
              Edit Number{" "}
            </span>
            <div className="otpContainer">
              <input
                name="otp1"
                type="text"
                inputmode="numeric"
                autoComplete="off"
                className="otpInput"
                pattern="\d*"
                value={otp1}
                onKeyPress={this.keyPressed}
                onChange={(e) =>
                  e.target.validity.valid ? this.handleChange("otp1", e) : null
                }
                tabIndex="1"
                maxLength="1"
                onKeyUp={(e) => this.inputfocus(e)}
              />
              <input
                name="otp2"
                type="text"
                pattern="\d*"
                autoComplete="off"
                inputmode="numeric"
                className="otpInput"
                value={otp2}
                onChange={(e) =>
                  e.target.validity.valid ? this.handleChange("otp2", e) : null
                }
                tabIndex="2"
                maxLength="1"
                onKeyUp={(e) => this.inputfocus(e)}
              />
              <input
                name="otp3"
                type="text"
                autoComplete="off"
                pattern="\d*"
                inputmode="numeric"
                className="otpInput"
                value={otp3}
                onChange={(e) =>
                  e.target.validity.valid ? this.handleChange("otp3", e) : null
                }
                tabIndex="3"
                maxLength="1"
                onKeyUp={(e) => this.inputfocus(e)}
              />
              <input
                name="otp4"
                type="text"
                pattern="\d*"
                inputmode="numeric"
                autoComplete="off"
                className="otpInput"
                value={otp4}
                onChange={(e) =>
                  e.target.validity.valid ? this.handleChange("otp4", e) : null
                }
                tabIndex="4"
                maxLength="1"
                onKeyUp={(e) => this.inputfocus(e)}
              />
            </div>
            {showOtpError ? (
              <p className="wrongOtp">WRONG OTP ENTERED</p>
            ) : (
              <>
                <span className="resendOtp"> Didn’t receive OTP?</span>
                {this.state.timer === 0 ? (
                  <span onClick={this.resendOTP} className="resendButton">
                    Resend OTP
                  </span>
                ) : (
                  <span className="resendOtp">
                    {" "}
                    Resend in 00:
                    {timer}
                  </span>
                )}
              </>
            )}

            <Button onClick={this.verifyOTP} type="submit">
              {showOtpError ? "Retry" : "Confirm"}
            </Button>
          </form>
        </>
      );
    } else {
      return (
        <>
          <form onSubmit={this.handleSubmit}>
            <span className="editInfo" onClick={this.showNumberInput}>
              Edit Number{" "}
            </span>
            <div>
              <input
                name="otp"
                type="text"
                inputmode="numeric"
                autoComplete="off"
                className="otpInputDesktop"
                pattern="\d*"
                value={otp}
                onChange={(e) =>
                  e.target.validity.valid ? this.handleChange("otp", e) : null
                }
                tabIndex="1"
                maxLength="4"
              />
            </div>
            {showOtpError ? (
              <p className="wrongOtp">WRONG OTP ENTERED</p>
            ) : (
              <>
                <span className="resendOtp"> Didn’t receive OTP?</span>
                {this.state.timer === 0 ? (
                  <span onClick={this.resendOTP} className="resendButton">
                    Resend OTP
                  </span>
                ) : (
                  <span className="resendOtp">
                    {" "}
                    Resend in 00:
                    {timer}
                  </span>
                )}
              </>
            )}

            <Button onClick={this.verifyOTP} type="submit">
              {showOtpError ? "Retry" : "Confirm"}
            </Button>
          </form>
        </>
      );
    }
  }
}

export default Otpinput;
