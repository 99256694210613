import React, { useCallback, useEffect, Component } from "react";
import cx from "classnames";
import "./slider.scss";
import BackIcon from "../../images/back.svg";

const SlideDrawer = ({ show, close, children, className }) => {
  let drawerClasses = "side-drawer";
  if (show) {
    drawerClasses = "side-drawer open";
  }

  const keyPress = useCallback(
    e => {
      if (e.key === "Escape" && show) {
        close();
      }
    },
    [show, close]
  );
  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  return (
    <>
      <div className={cx(drawerClasses, className)}>
        <div className="backIcon">
          <img onClick={close} src={BackIcon} alt="back-icon" />
        </div>
        <div>{children}</div>
      </div>
    </>
  );
};

const SliderDrawerLoungeDetails = ({ show, close, children }) => {
  let drawerClasses = "sliderDrawerLoungeDetails";
  if (show) {
    document.body.style.overflow = "hidden";
    drawerClasses = "sliderDrawerLoungeDetailsOpen";
  } else {
    document.body.style.overflow = "initial";
  }

  const keyPress = useCallback(
    e => {
      if (e.key === "Escape" && show) {
        close();
      }
    },
    [show, close]
  );
  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  return (
    <>
      <div className={drawerClasses}>
        <div className="closeIcon">
          <img
            onClick={close}
            src="https://d1flzashw70bti.cloudfront.net/original/images/lounges/close-cross.svg"
            alt="back-icon"
          />
        </div>
        <div>{children}</div>
      </div>
    </>
  );
};

class MenuSlideDrawer extends Component {
  render() {
    const { showSideMenu, toggleSideMenu, children, isMobile } = this.props;

    return showSideMenu ? (
      <div className="menuSlideDrawer">
        {isMobile ? (
          <div className="menuToggle">
            <img
              onClick={toggleSideMenu}
              src="https://d1flzashw70bti.cloudfront.net/original/images/closeMenuIcon.svg"
              alt="close menu icon"
            />
          </div>
        ) : (
          <div className="menuToggle">
            <span className="menuText">Menu</span>
            <img
              onClick={toggleSideMenu}
              className="closeMenuIcon"
              src="https://d1flzashw70bti.cloudfront.net/original/images/closeMenuIconDweb.svg"
              alt="close menu icon"
            />
          </div>
        )}
        <div className="menuItems">{children}</div>
      </div>
    ) : null;
  }
}

export { SlideDrawer, SliderDrawerLoungeDetails, MenuSlideDrawer };
