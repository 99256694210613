import React from "react";
import styles from "./details.module.scss";
const PocDetails = (props) => {
  const { details, editPoc, isMobile } = props;
  const showEditModal = (data) => {
    editPoc(data);
  };
  if (Array.isArray(details) && details.length > 0) {
    if (isMobile) {
      return (
        <>
          {details.map((detail, index) => (
            <div className={styles.box} key={detail.mobile}>
              <div className={styles.upper}>
                <p className={styles.bold}>#{index + 1}</p>
                <p
                  className={styles.button}
                  onClick={() => showEditModal(index)}
                >
                  Edit
                </p>
              </div>
              <div className={styles.wrapper}>
                <div className={styles.first}>
                  <p className={styles.bold}>Name</p>
                  <p className={styles.text}>{detail.name}</p>
                </div>
                <div className={styles.second}>
                  <p className={styles.bold}>Area</p>
                  <p className={styles.text}>{detail.area}</p>
                </div>
              </div>
              <div className={styles.wrapper}>
                <div className={styles.first}>
                  <p className={styles.bold}>Phone</p>
                  <p className={styles.text}>{detail.mobile}</p>
                </div>
                <div className={styles.second}>
                  <p className={styles.bold}>Department</p>
                  <p className={styles.text}>{detail.department}</p>
                </div>
              </div>
            </div>
          ))}
        </>
      );
    } else {
      return (
        <>
          {details.map((detail, index) => (
            <div className={styles.box} key={detail.mobile}>
              <div className={styles.details}>
                <p className={styles.bold}>#{index + 1}</p>
              </div>

              <div className={styles.details}>
                <p className={styles.bold}>Name</p>
                <p className={styles.text}>{detail.name}</p>
              </div>
              <div className={styles.details}>
                <p className={styles.bold}>Area</p>
                <p className={styles.text}>{detail.area}</p>
              </div>
              <div className={styles.details}>
                <p className={styles.bold}>Phone</p>
                <p className={styles.text}>{detail.mobile}</p>
              </div>
              <div className={styles.details}>
                <p className={styles.bold}>Department</p>
                <p className={styles.text}>{detail.department}</p>
              </div>
              <div className={styles.details}>
                <button
                  className={styles.button}
                  onClick={() => showEditModal(index)}
                >
                  Edit
                </button>
              </div>
            </div>
          ))}
        </>
      );
    }
  } else {
    return <></>;
  }
};
export default PocDetails;
