import React, { Component } from "react";
import "./mobileMainHeader.scss";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

class MobileMainHeader extends Component {
  render() {
    const { isMainHeaderHidden } = this.props;
    return (
      <div
        className={
          isMainHeaderHidden
            ? `mobileMainHeader ${isMainHeaderHidden}`
            : "mobileMainHeader"
        }
      >
        <div className="logo">
          <Link to="/">
            <img
              src="https://d1flzashw70bti.cloudfront.net/original/images/operator/desktop/logo.svg"
              alt="Zingbus Logo"
            />
          </Link>
        </div>
        <div className="menuIcon" onClick={this.props.toggleSideMenu}>
          <img
            src="https://d1flzashw70bti.cloudfront.net/original/images/menuHamburgerIcon.svg"
            alt="Menu Icon"
          />
        </div>
      </div>
    );
  }
}

export default MobileMainHeader;
