import React, { Component } from "react";
import Fetch from "../../utils/fetch";
import Navbar from "../../components/newNavBar";
import {
  Table,
  Select,
  DatePicker,
  Modal,
  message,
  ConfigProvider,
  Button,
  Image,
} from "antd";
import Loader from "../../utils/loader";
import less from "./feedback.module.scss";
import cx from "classnames";
import MobileMainHeader from "../../components/headers/mobileMainHeader";
import { MenuSlideDrawer } from "../../components/slideDrawer/SlideDrawer";
import SideMenu from "../../components/Layout/SideMenu";
import FeedbackDataTile from "../../components/feedbackDataTile";
import FeedbackDataMoreInfoTile from "../../components/feedbackDataTile/MoreInfoTile";
import { getHoursAndMinFromMillis } from "../../utils/common";
import moment from "moment";
const { RangePicker } = DatePicker;

const columns = [
  {
    title: "Service Name",
    dataIndex: "name",
  },
  {
    title: "Date",
    dataIndex: "date",
  },
  {
    title: "Avg. Ratings",
    dataIndex: "ratings",
  },
  {
    title: "View Data",
    dataIndex: "view",
    render: ({ showMoreDetails, _id }) => {
      return (
        <div>
          <Button onClick={() => showMoreDetails({ _id })}>View</Button>
        </div>
      );
    },
  },
];
const moreDetailsColumns = [
  {
    title: "Serial",
    dataIndex: "serial",
    width: "15%",
  },
  {
    title: "PNR",
    dataIndex: "pnr",
    width: "15%",
  },
  {
    title: "Ratings",
    dataIndex: "ratings",
    width: "15%",
  },
  {
    title: "Tags",
    dataIndex: "tags",
    width: "30%",
    render: ({ isPositive, data }) => {
      if (data && data.length > 0) {
        return (
          <div style={{ display: "flex", flexWrap: "wrap", gap: "6px" }}>
            {data.map((tag, index) => (
              <div
                key={index}
                className={isPositive ? less.tag : less.tagNegative}
              >
                <div>
                  <span>{tag}</span>
                </div>
              </div>
            ))}
          </div>
        );
      } else {
        return <b>No Tag</b>;
      }
    },
  },
  {
    title: "Comments",
    dataIndex: "comments",
    width: "20%",
  },
];

class ratingData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tableLoader: false,
      isMobile: false,
      moreDetailsData: [],
      showMoreDetails: false,
      busRcsOptions: [],
      ratingTableData: [],
      showSideMenu: false,
      fromDate: null,
      toDate: null,
    };
  }

  async componentDidMount() {
    try {
      const regex =
        /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      const isMobile = regex.test(navigator.userAgent);
      const stateObj = { loading: false, isMobile };
      const busRcsOptions = await this.fetchRc();
      const data = await this.fetchRatings({ busRcsOptions });
      stateObj.busRcsOptions = busRcsOptions;
      stateObj.ratingTableData = data.ratingTableData;
      stateObj.ratingData = data.ratingData;
      stateObj.averageTotalRatings = data.averageTotalRatings;
      stateObj.negativeRating = data.negativeRating;
      stateObj.positiveRating = data.positiveRating;

      this.setState(stateObj);
    } catch (err) {
      console.log(err);
    }
  }

  fetchRc = async () => {
    const url = `/user/rc`;
    const options = {
      method: "get",
    };
    const response = await Fetch(url, options);
    if (Array.isArray(response) && response.length > 0) {
      let busRcsOptions = [];
      response.map((data) => {
        let selectOpt = {
          value: data.rc,
          label: data.rc,
        };
        busRcsOptions.push(selectOpt);
      });
      return busRcsOptions;
    } else {
      message.error("something went wrong");
      return [];
    }
  };

  fetchRatings = async (data, clearDate) => {
    const { rc, fromDate, toDate, busRcsOptions } = this.state;
    let url = `/user/fetchRatings?`;
    let fetchObj = {};
    if (data.rc || rc) {
      fetchObj.rc = data.rc ? [data.rc] : [rc];
    } else {
      let rcArray = data.busRcsOptions ? data.busRcsOptions : busRcsOptions;
      fetchObj.rc = rcArray.map((obj) => {
        return obj.value;
      });
    }
    if (!clearDate) {
      if (data.fromDate || fromDate) {
        fetchObj.fromDate = data.fromDate ? data.fromDate : fromDate;
      }
      if (data.toDate || toDate) {
        fetchObj.toDate = data.toDate ? data.toDate : toDate;
      }
    }
    const options = {
      method: "post",
      data: fetchObj,
    };
    const response = await Fetch(url, options);
    const { ratingData, averageTotalRatings, negativeRating, positiveRating } =
      response;
    if (Array.isArray(ratingData) && ratingData.length > 0) {
      let ratingTableData;
      ratingTableData = ratingData.map((obj) => {
        const avgRating = (obj.totalRatings / obj.bookings.length).toFixed(1);
        return {
          name: obj.service ? obj.service.name : null,
          date: moment(obj.tripDate).format("DD-MMM-YY"),
          view: {
            showMoreDetails: this.showMoreDetails,
            _id: obj.tripId,
          },
          ratings: avgRating,
          key: obj.tripId,
        };
      });

      let returnObj = {
        ratingTableData,
        ratingData,
        averageTotalRatings,
        negativeRating,
        positiveRating,
      };
      return returnObj;
    } else {
      message.error("No Data Found");
      return [];
    }
  };

  showMoreDetails = (selectedId) => {
    debugger;
    const { ratingData } = this.state;
    const ratingMoreData = ratingData.filter(
      (obj) => obj.tripId == selectedId._id
    )[0];
    const showMorebusRc = ratingMoreData.busRc;
    const showMoreServiceName = ratingMoreData.service
      ? ratingMoreData.service.name
      : null;

    let count = 0;
    let sumOfRatings = 0;
    const moreDetailsData = ratingMoreData.bookings.map((obj) => {
      count = count + 1;
      let data = {
        serial: count,
        pnr: obj.bookingCode,
        ratings: obj.rating,
        comments: obj.comments,

        tags: {
          isPositive: obj.rating > 3,
          data: obj.tags,
        },
      };
      sumOfRatings += obj.rating;
      return data;
    });

    const averageRating = sumOfRatings / moreDetailsData.length;
    this.setState({
      showMoreDetails: true,
      moreDetailsData,
      showMorebusRc,
      showMoreServiceName,
      averageRatingForTrip: averageRating,
    });
  };
  hideShowMore = () => {
    this.setState({
      showMoreDetails: false,
      moreDetailsData: [],
      showMorebusRc: undefined,
      showMoreServiceName: undefined,
      averageRatingForTrip: undefined,
    });
  };

  disabled7DaysDate = (current, { from }) => {
    if (from) {
      return Math.abs(current.diff(from, "days")) >= 14;
    }
    return false;
  };

  onRangeChange = async (dates, dateStrings) => {
    if (dates) {
      const fromDate = moment(dateStrings[0], "DD/MM/YYYY").valueOf();
      const toDate = moment(dateStrings[1], "DD/MM/YYYY")
        .endOf("day")
        .valueOf();

      const obj = { fromDate, toDate };
      const data = await this.fetchRatings(obj);

      this.setState({
        fromDate,
        toDate,
        ratingTableData: data.ratingTableData,
        ratingData: data.ratingData,
        averageTotalRatings: data.averageTotalRatings,
        negativeRating: data.negativeRating,
        positiveRating: data.positiveRating,
      });
    } else {
      const obj = { fromDate: null, toDate: null };
      const data = await this.fetchRatings(obj, true);

      this.setState({
        fromDate: null,
        toDate: null,
        ratingTableData: data.ratingTableData,
        ratingData: data.ratingData,
        averageTotalRatings: data.averageTotalRatings,
        negativeRating: data.negativeRating,
        positiveRating: data.positiveRating,
      });
    }
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  directSearh = async (key, value) => {
    const fetchData = { [key]: value };
    const data = await this.fetchRatings(fetchData);
    this.setState({
      [key]: value,
      ratingTableData: data.ratingTableData,
      ratingData: data.ratingData,
      averageTotalRatings: data.averageTotalRatings,
      negativeRating: data.negativeRating,
      positiveRating: data.positiveRating,
    });
  };
  toggleSideMenu = () => {
    const { showSideMenu } = this.state;
    this.setState({
      showSideMenu: !showSideMenu,
    });
  };

  render() {
    const {
      loading,
      isMobile,
      rc,
      tableLoader,
      ratingTableData,
      moreDetailsData,
      busRcsOptions,
      showMorebusRc,
      showMoreServiceName,
      showMoreDetails,
      averageTotalRatings,
      averageRatingForTrip,
      negativeRating,
      positiveRating,
      showSideMenu,
    } = this.state;

    let active = 4;

    if (loading) {
      return (
        <div className={less.loader}>
          <Loader />
        </div>
      );
    } else {
      return (
        <>
          {isMobile ? (
            <>
              <MobileMainHeader toggleSideMenu={this.toggleSideMenu} />

              {showSideMenu ? (
                <MenuSlideDrawer
                  toggleSideMenu={this.toggleSideMenu}
                  showSideMenu={showSideMenu}
                  isMobile={isMobile}
                >
                  <SideMenu
                    active={active}
                    toggleSideMenu={this.toggleSideMenu}
                    isMobile={isMobile}
                  />
                </MenuSlideDrawer>
              ) : null}
              <div style={{ paddingTop: "75px" }}>
                <div className={less.pageHeading}>
                  {!showMoreDetails ? (
                    <span className={less.pageHeadingText}>Feedback Data</span>
                  ) : (
                    <>
                      <img
                        src="https://d1flzashw70bti.cloudfront.net/original/images/operator/backarrow.svg"
                        alt="back arrow"
                        onClick={this.hideShowMore}
                        className={less.backImg}
                      />
                      <div className={less.showMorePageHeading}>
                        <span className={less.pageHeadingText}>
                          {showMoreServiceName}
                        </span>
                        <div className={less.showMorepPageHeadingRC}>
                          <span>{showMorebusRc}</span>
                        </div>
                        <div className={less.showMorepPageHeadingRating}>
                          <span> {averageRatingForTrip}</span>
                          <img
                            src="https://d1flzashw70bti.cloudfront.net/original/images/operator/rating/star.svg"
                            alt="star"
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="container">
                {!showMoreDetails ? (
                  <>
                    <div className={less.searchHeader}>
                      <div>
                        <div>
                          <RangePicker
                            onChange={this.onRangeChange}
                            disabledDate={this.disabled7DaysDate}
                            format="DD/MM/YYYY"
                            popupClassName="dateRangePicker"
                          />
                        </div>
                      </div>
                      <div style={{ height: "10px" }}></div>
                      <div>
                        {" "}
                        <Select
                          style={{ width: "100%", marginRight: "4px" }}
                          placeholder="Select a RC"
                          onChange={(value) => this.directSearh("rc", value)}
                          options={busRcsOptions}
                        />
                      </div>
                    </div>
                    <div className={less.ratingInfo}>
                      <div className={less.avgRating}>
                        <img
                          src="https://d1flzashw70bti.cloudfront.net/original/images/operator/rating/rate.svg"
                          alt="star"
                        />
                        <div>
                          <span>Average Rating</span>
                          <span>{averageTotalRatings}</span>
                        </div>
                      </div>
                      <div className={less.positiveRating}>
                        <img
                          src="https://d1flzashw70bti.cloudfront.net/original/images/operator/rating/positive.svg"
                          alt="ok"
                        />
                        <div>
                          <div>
                            {" "}
                            <span>Positive Rating</span>{" "}
                            <div>
                              {" "}
                              <span>4 to 5</span>
                              <img
                                src="https://d1flzashw70bti.cloudfront.net/original/images/operator/rating/star.svg"
                                alt="star"
                              />
                            </div>
                          </div>
                          <span>{positiveRating}</span>
                        </div>
                      </div>
                      <div className={less.negRating}>
                        <img
                          src="https://d1flzashw70bti.cloudfront.net/original/images/operator/rating/negative.svg"
                          alt="bad"
                        />
                        <div>
                          <div>
                            {" "}
                            <span>Negative Ratings</span>{" "}
                            <div>
                              {" "}
                              <span>1 to 3</span>
                              <img
                                src="https://d1flzashw70bti.cloudfront.net/original/images/operator/rating/star.svg"
                                alt="star"
                              />
                            </div>
                          </div>
                          <span>{negativeRating}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                <div className={less.inspectionDataContainer}>
                  {!showMoreDetails && ratingTableData && ratingTableData.length
                    ? ratingTableData.map((data, index) => (
                        <FeedbackDataTile
                          key={index}
                          id={data.view}
                          name={data.name}
                          date={moment(data.date).format("DD-MMM-YY")}
                          ratings={data.ratings}
                          onButtonClick={this.showMoreDetails}
                        />
                      ))
                    : showMoreDetails &&
                      moreDetailsData &&
                      moreDetailsData.length
                    ? moreDetailsData.map((data, index) => {
                        return data ? (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <FeedbackDataMoreInfoTile
                              key={index}
                              index={data.serial}
                              serial={data.serial}
                              pnr={data.pnr}
                              ratings={data.ratings}
                              comments={data.comments}
                              tags={data.tags}
                            />
                          </div>
                        ) : null;
                      })
                    : null}
                </div>
              </div>
            </>
          ) : (
            <div>
              <Navbar selectedKey={4} isMobile={isMobile} />
              <div className="container">
                <div className={cx("DealsHeader", less.amenitiesRow)}>
                  <span className="dealSpan">Feedback Data</span>
                </div>
                <div className={less.searchHeader}>
                  <div>
                    {" "}
                    <Select
                      style={{ width: "50%" }}
                      placeholder="Select a RC"
                      onChange={(value) => this.directSearh("rc", value)}
                      options={busRcsOptions}
                    />
                  </div>
                  <div>
                    <div>
                      <RangePicker
                        onChange={this.onRangeChange}
                        disabledDate={this.disabled7DaysDate}
                        format="DD/MM/YYYY"
                      />
                    </div>
                  </div>
                </div>

                <div className={less.ratingInfo}>
                  <div className={less.avgRating}>
                    <img
                      src="https://d1flzashw70bti.cloudfront.net/original/images/operator/rating/rate.svg"
                      alt="star"
                    />
                    <div>
                      <span>Average Rating</span>
                      <span>{averageTotalRatings}</span>
                    </div>
                  </div>
                  <div className={less.positiveRating}>
                    <img
                      src="https://d1flzashw70bti.cloudfront.net/original/images/operator/rating/positive.svg"
                      alt="ok"
                    />
                    <div>
                      <div>
                        {" "}
                        <span>Positive Rating</span>{" "}
                        <div>
                          {" "}
                          <span>4 to 5</span>
                          <img
                            src="https://d1flzashw70bti.cloudfront.net/original/images/operator/rating/star.svg"
                            alt="star"
                          />
                        </div>
                      </div>
                      <span>{positiveRating}</span>
                    </div>
                  </div>
                  <div className={less.negRating}>
                    <img
                      src="https://d1flzashw70bti.cloudfront.net/original/images/operator/rating/negative.svg"
                      alt="bad"
                    />
                    <div>
                      <div>
                        {" "}
                        <span>Negative Ratings</span>{" "}
                        <div>
                          {" "}
                          <span>1 to 3</span>
                          <img
                            src="https://d1flzashw70bti.cloudfront.net/original/images/operator/rating/star.svg"
                            alt="star"
                          />
                        </div>
                      </div>
                      <span>{negativeRating}</span>
                    </div>
                  </div>
                </div>
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#7b2bff",
                    },
                  }}
                >
                  <Table
                    loading={tableLoader}
                    columns={columns}
                    bordered={true}
                    scroll={{ x: "max-content", y: isMobile ? "70vh" : null }}
                    dataSource={ratingTableData}
                    pagination={false}
                  />
                </ConfigProvider>
              </div>

              <Modal
                closeIcon={null}
                width={"80%"}
                open={showMoreDetails}
                footer={null}
              >
                <div className={less.moreDetailsModal}>
                  <div>
                    <p>Feedback Data </p>
                    <p>{showMorebusRc}</p>
                    <div>
                      <p>{averageRatingForTrip}</p>
                      <img
                        src="https://d1flzashw70bti.cloudfront.net/original/images/operator/rating/star.svg"
                        alt="star"
                      />
                    </div>
                  </div>
                  <img
                    src="https://d1flzashw70bti.cloudfront.net/original/images/operator/inspection/close.svg"
                    alt="close"
                    onClick={() =>
                      this.setState({
                        showMoreDetails: false,
                        moreDetailsData: [],
                      })
                    }
                  />
                </div>
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#7b2bff",
                    },
                  }}
                >
                  <Table
                    columns={moreDetailsColumns}
                    dataSource={moreDetailsData}
                    pagination={false}
                  ></Table>
                </ConfigProvider>
              </Modal>
            </div>
          )}
        </>
      );
    }
  }
}

export default ratingData;
