import React, { Component } from "react";
import Fetch from "../../utils/fetch";
import Navbar from "../../components/newNavBar";
import styles from "./operatorDealsDetails.module.scss";
import { Switch, ConfigProvider, Space, message, Modal } from "antd";
import Loader from "../../utils/loader";
import moment from "moment";
class OperatorDealsDetails extends Component {
  state = {
    campaigns: [],
    loading: true,
    selectedId: "",
    showConfirmationModal: false,
    selectedObj: {},
    isMobile: false,
  };

  async componentDidMount() {
    try {
      const campaigns = await this.fetchCampaigns();
      const regex =
        /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      const isMobile = regex.test(navigator.userAgent);
      this.setState({ campaigns, loading: false, isMobile });
    } catch (err) {
      console.log(err);
    }
  }

  fetchCampaigns = async (id) => {
    const url = `/user/fetchDeals`;
    const options = {
      method: "get",
    };
    const response = await Fetch(url, options);
    if (Array.isArray(response) && response.length > 0) {
      return response;
    } else {
      message.error("no deals found");
      return [];
    }
  };

  showDealDetails = async (selectedObj) => {
    this.props.history.push(`/operatorDealsDetails/${selectedObj._id}`);
  };

  showDeals = () => {
    this.props.history.push("deals");
  };

  statusChangeHandle = (id, value) => {
    let stateObj = { campaignUpdate: {} };
    stateObj.campaignUpdate.selectedCampaignId = id;
    stateObj.campaignUpdate.status = value ? "ACTIVE" : "INACTIVE";
    stateObj.showConfirmationModal = true;
    this.setState(stateObj);
  };

  closeModal = () => {
    let stateObj = { campaignUpdate: {} };
    stateObj.showConfirmationModal = false;
    this.setState(stateObj);
  };

  updateCampaign = async () => {
    let stateObj = {};
    stateObj.showConfirmationModal = false;

    const { campaigns } = this.state;
    const { campaignUpdate } = this.state;
    const url = `/user/updateCampaign`;
    const options = {
      method: "post",
      data: campaignUpdate,
    };
    const response = await Fetch(url, options);
    if (Array.isArray(response) && response.length > 0) {
      let updatedCampaigns = campaigns.map((obj) => {
        if (obj._id == campaignUpdate.selectedCampaignId) {
          let updatedObj = {
            ...obj,
            status: response[0].status,
          };
          return updatedObj;
        }
        return obj;
      });
      stateObj.campaigns = updatedCampaigns;
    } else {
      message.error("something went wrong");
    }
    this.setState(stateObj);
  };

  updateBackSteps = () => {
    this.props.history.goBack();
  };

  render() {
    const {
      campaigns,
      loading,
      showConfirmationModal,
      campaignUpdate,
      isMobile,
    } = this.state;
    if (loading) {
      return (
        <div className="loader">
          <Loader />
        </div>
      );
    } else if (isMobile) {
      return (
        <div>
          <Navbar isMobile={isMobile} />
          <div>
            <div className="DealsHeader">
              <div className="headerDetails">
                <img
                  src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deal/back.svg"
                  alt="back"
                  onClick={() => {
                    this.updateBackSteps();
                  }}
                />
                <span className="dealSpan">Operator Deal </span>
              </div>
              <div className={styles.dealButton} onClick={this.showDeals}>
                <p>Create Deal</p>
              </div>
            </div>
            {campaigns.length > 0
              ? campaigns.map((obj) => {
                  return (
                    <>
                      <div className={styles.box}>
                        <div className={styles.details}>
                          <div className={styles.content}>
                            <span className={styles.name}>
                              {obj.operatorDeal.type}
                            </span>
                            <img
                              src={
                                obj.operatorDeal.type == "LASTMINUTE"
                                  ? "https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/lastMin.svg"
                                  : obj.operatorDeal.type == "EARLYBIRD"
                                  ? "https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/earlyBird.svg"
                                  : "https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/custom.svg"
                              }
                              alt="bird"
                            />
                          </div>
                          {obj.endDate < Date.now() ? (
                            <p className={styles.expired}>Expired</p>
                          ) : (
                            <ConfigProvider
                              theme={{
                                token: {
                                  colorPrimary: "#5BA300",
                                  colorBgContainer: "#7b2bff",
                                },
                              }}
                            >
                              <Space>
                                <Switch
                                  onChange={(checked) =>
                                    this.statusChangeHandle(obj._id, checked)
                                  }
                                  checked={
                                    obj.status == "ACTIVE" ? true : false
                                  }
                                  id={obj._id}
                                  type="primary"
                                  checkedChildren="ON"
                                  unCheckedChildren="OFF"
                                />
                              </Space>
                            </ConfigProvider>
                          )}
                        </div>
                        <span className={styles.name}>
                          Campaign Code : {obj.name}
                        </span>
                        <div className={styles.dayDetails}>
                          <span>
                            {obj.NoOfDays} {obj.NoOfDays < 2 ? "day" : "days"}
                          </span>
                          <span>&#8226;</span>
                          <span>
                            {moment(obj.startDate).format("DD/MM/YY")} {" to "}
                            {moment(obj.endDate).format("DD/MM/YY")}
                          </span>
                        </div>
                        <div className={styles.serviceDetails}>
                          {obj.serviceName ? (
                            <span>
                              {obj.serviceName[0].fromCityName}
                              {" to "}
                              {obj.serviceName[0].toCityName}
                              {obj.serviceName.length < 2 ? null : " + "}
                              {obj.serviceName.length < 2
                                ? null
                                : obj.serviceName.length - 1}
                            </span>
                          ) : (
                            <div></div>
                          )}

                          <span
                            onClick={() => {
                              this.showDealDetails(obj);
                            }}
                          >
                            View Details
                          </span>
                        </div>
                      </div>
                    </>
                  );
                })
              : null}
            <Modal
              centered
              open={showConfirmationModal}
              onCancel={this.closeModal}
              cancelButtonProps={{ style: { display: "none" } }}
              okButtonProps={{ style: { display: "none" } }}
            >
              <div className="modalDiv">
                <img
                  src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deal/warning.svg"
                  alt="warning"
                />
                <p className="modalHeader">
                  Are you sure that you want to turn{" "}
                  {campaignUpdate && campaignUpdate.status == "INACTIVE"
                    ? "off"
                    : "on"}{" "}
                  this campaign
                </p>
                <button onClick={this.updateCampaign} className="primary">
                  Yes
                </button>
                <p className={styles.cancel} onClick={this.closeModal}>
                  No
                </p>
              </div>
            </Modal>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <Navbar selectedKey={2} isMobile={isMobile} />
          <div className="container">
            <div className="DealsHeader">
              <span className="dealSpan">Operator Deal </span>
              <div className={styles.dealButton} onClick={this.showDeals}>
                <p>+ Create Deal</p>
              </div>
            </div>
            {campaigns.length > 0
              ? campaigns.map((obj) => {
                  return (
                    <>
                      <div className={styles.box}>
                        <div className={styles.details}>
                          <div className={styles.type}>
                            <img
                              src={
                                obj.operatorDeal.type == "LASTMINUTE"
                                  ? "https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/lastMin.svg"
                                  : obj.operatorDeal.type == "EARLYBIRD"
                                  ? "https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/earlyBird.svg"
                                  : "https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/custom.svg"
                              }
                              alt={obj.operatorDeal.type}
                            />
                            <div>
                              <p>{obj.operatorDeal.type}</p>
                            </div>
                          </div>
                        </div>
                        <div className={styles.details}>
                          <p>Campaign Code</p>
                          <span className={styles.name}>{obj.name}</span>
                        </div>
                        <div className={styles.details}>
                          <p>{obj.NoOfDays} days</p>
                          <span className={styles.name}>
                            {moment(obj.startDate).format("DD/MM/YY")} {" to "}
                            {moment(obj.endDate).format("DD/MM/YY")}
                          </span>
                        </div>
                        <div className={styles.details}>
                          <p>Services</p>
                          {obj.serviceName ? (
                            <>
                              <span className={styles.name}>
                                {obj.serviceName[0].fromCityName}
                                {" to "}
                                {obj.serviceName[0].toCityName}
                              </span>
                              <span className={styles.extra}>
                                {obj.serviceName.length < 2 ? null : " + "}
                                {obj.serviceName.length < 2
                                  ? null
                                  : obj.serviceName.length - 1}
                              </span>
                            </>
                          ) : (
                            <div></div>
                          )}
                        </div>
                        <div className={styles.details}>
                          {obj.endDate < Date.now() ? (
                            <span className={styles.expired}>Expired</span>
                          ) : (
                            <ConfigProvider
                              theme={{
                                token: {
                                  colorPrimary: "#5BA300",
                                  colorBgContainer: "#7b2bff",
                                },
                              }}
                            >
                              <Space>
                                <Switch
                                  onChange={(checked) =>
                                    this.statusChangeHandle(obj._id, checked)
                                  }
                                  checked={
                                    obj.status == "ACTIVE" ? true : false
                                  }
                                  id={obj._id}
                                  type="primary"
                                  checkedChildren="ON"
                                  unCheckedChildren="OFF"
                                />
                              </Space>
                            </ConfigProvider>
                          )}
                        </div>
                        <div className={styles.details}>
                          <img
                            onClick={() => {
                              this.showDealDetails(obj);
                            }}
                            src="https://d1flzashw70bti.cloudfront.net/original/images/operator/dealDetails/arrow.svg"
                            alt="arrow"
                          />
                        </div>
                      </div>
                    </>
                  );
                })
              : null}
            <Modal
              centered
              open={showConfirmationModal}
              onCancel={this.closeModal}
              cancelButtonProps={{ style: { display: "none" } }}
              okButtonProps={{ style: { display: "none" } }}
            >
              <div className="modalDiv">
                <img
                  src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deal/warning.svg"
                  alt="warning"
                />
                <p className="modalHeader">
                  Are you sure that you want to turn{" "}
                  {campaignUpdate && campaignUpdate.status == "INACTIVE"
                    ? "off"
                    : "on"}{" "}
                  this campaign
                </p>
                <button
                  onClick={this.updateCampaign}
                  className="primary rectangle"
                >
                  Yes
                </button>
                <p className="cancelButton" onClick={this.closeModal}>
                  No
                </p>
              </div>
            </Modal>
          </div>
        </div>
      );
    }
  }
}

export default OperatorDealsDetails;
