import React, { Component } from "react";
import "./moreInfoTile.scss";

class MoreInfoTile extends Component {
  render() {
    const { serial, pnr, ratings, comments, tags } = this.props;
    console.log(tags);

    return (
      <div className="MoreInfoTile">
        <div className="heading">
          <span>{`Serial Number: ${serial}`}</span>
        </div>
        <div className="subHeading">
          <span>PNR:</span>
          <span>{pnr}</span>
        </div>
        <div className="subHeading">
          <span>Ratings:</span>
          <span>{ratings}</span>
        </div>
        <div className="subHeading">
          <span>Tags:</span>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "6px",
              justifyContent: "flex-end",
            }}
          >
            {tags.data.map((tag, index) => (
              <div
                key={index}
                className={tags.isPositive ? "tag" : "tagNegative"}
              >
                <div>
                  <span>{tag}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="subHeadingComment">
          <span>Comment:</span>
          <span>{comments}</span>
        </div>
      </div>
    );
  }
}

export default MoreInfoTile;
