import React, { Component } from "react";
import Fetch from "../../utils/fetch";
import Loader from "../../utils/loader";
import {
  Switch,
  ConfigProvider,
  Space,
  Divider,
  message,
  Modal,
  Table,
} from "antd";
import { getHoursAndMinFromMillis } from "../../utils/common";
import moment from "moment";
import styles from "./dealDetail.module.scss";

const columns = [
  {
    title: "Service Name",
    dataIndex: "name",
    width: "30%",
  },
  {
    title: "Bus Type",
    dataIndex: "busAsset",
    width: "30%",
  },
  {
    title: "Start Time",
    dataIndex: "time",
    width: "30%",
  },
];
class OperatorDealsDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmationModal: false,
      loading: true,
      campaigns: {},
      campaignId: props.match.params.campaignId,
    };
  }

  async componentDidMount() {
    try {
      const campaigns = await this.fetchCampaigns();
      const regex =
        /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      const isMobile = regex.test(navigator.userAgent);
      this.setState({ campaigns, loading: false, isMobile });
    } catch (err) {
      console.log(err);
    }
  }

  fetchCampaigns = async () => {
    const { campaignId } = this.state;
    const url = `/user/fetchDeals?id=${campaignId}`;
    const options = {
      method: "get",
    };
    const response = await Fetch(url, options);
    if (Array.isArray(response) && response.length > 0) {
      const campaign = response[0];
      const { exclusionDates, serviceName } = campaign;
      let sortedServiceName, serviceTable;
      let date = "";
      if (exclusionDates) {
        for (let i = 0; i < exclusionDates.length; i++) {
          if (date.length == 0) {
            date += moment(exclusionDates[i]).format("DD/MM/YY");
          } else {
            let dateInString =
              " , " + moment(exclusionDates[i]).format("DD/MM/YY");
            date += dateInString;
          }
        }
      }
      if (serviceName) {
        sortedServiceName = serviceName.sort(function (a, b) {
          if (a.fromCityName < b.fromCityName) {
            return -1;
          }
          if (a.fromCityName > b.fromCityName) {
            return 1;
          }
          return 0;
        });

        serviceTable = sortedServiceName.map((service) => {
          return {
            name: service.fromCityName + " to " + service.toCityName,
            _id: service._id,
            time: getHoursAndMinFromMillis(service.startTime),
            busAsset: service.b2cAssetTypeName,
            key: service._id,
          };
        });
      }

      let returnObj = {
        ...campaign,
        serviceName: sortedServiceName,
        serviceTable,
        date,
      };
      return returnObj;
    } else {
      message.error("something went wrong");
      return {};
    }
  };

  backPressed = () => {
    this.props.history.goBack();
  };

  statusChangeHandle = (id, value) => {
    let stateObj = { campaignUpdate: {} };
    stateObj.campaignUpdate.selectedCampaignId = id;
    stateObj.campaignUpdate.status = value ? "ACTIVE" : "INACTIVE";
    stateObj.showConfirmationModal = true;
    this.setState(stateObj);
  };

  closeModal = () => {
    let stateObj = { campaignUpdate: {} };
    stateObj.showConfirmationModal = false;
    this.setState(stateObj);
  };

  updateCampaign = async () => {
    let stateObj = {};
    stateObj.showConfirmationModal = false;
    const { campaigns } = this.state;
    const { campaignUpdate } = this.state;
    const url = `/user/updateCampaign`;
    const options = {
      method: "post",
      data: campaignUpdate,
    };
    const response = await Fetch(url, options);
    if (Array.isArray(response) && response.length > 0) {
      let updatedCampaigns = {
        ...campaigns,
        status: response[0].status,
      };
      stateObj.campaigns = updatedCampaigns;
    } else {
      message.error("something went wrong");
    }
    this.setState(stateObj);
  };

  render() {
    const { campaigns, loading, showConfirmationModal, isMobile } = this.state;
    if (loading) {
      return (
        <div className="loader">
          <Loader />
        </div>
      );
    } else if (isMobile) {
      return (
        <>
          <div className="DealsHeader">
            <div className="headerDetails">
              <img
                src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deal/back.svg"
                alt="back"
                onClick={() => {
                  this.backPressed();
                }}
              />
              <span className="dealSpan">Deal Details </span>
            </div>
            <div>
              {campaigns.endDate < Date.now() ? (
                <p className={styles.expired}>Expired</p>
              ) : (
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#5BA300",
                      colorBgContainer: "#7b2bff",
                    },
                  }}
                >
                  <Space>
                    <Switch
                      onChange={(checked) =>
                        this.statusChangeHandle(campaigns._id, checked)
                      }
                      type="primary"
                      checkedChildren="ON"
                      unCheckedChildren="OFF"
                      checked={campaigns.status == "ACTIVE" ? true : false}
                    />
                  </Space>
                </ConfigProvider>
              )}
            </div>
          </div>
          <div className="step">
            <div>
              <p className="dealSpan">Detail Type</p>
              <div className="finalData">
                <span className="subHeading">Campaign Type : </span>
                <span className="subHeading">
                  {campaigns.operatorDeal.type}
                </span>
              </div>
              <div className="finalData">
                <span className="subHeading">Discount Type : </span>
                <span className="subHeading">
                  {campaigns.operatorDeal.discountType == "ABSOLUTE"
                    ? "FLAT"
                    : campaigns.operatorDeal.discountType}
                </span>
              </div>

              <div className="finalData">
                <span className="subHeading">
                  {campaigns.operatorDeal.discountType == "ABSOLUTE"
                    ? "Discount Amount : ₹"
                    : "Discount Percentage :"}{" "}
                </span>
                <span className="subHeading">
                  {campaigns.operatorDeal.discountType == "ABSOLUTE"
                    ? campaigns.operatorDeal.discountValue
                    : campaigns.operatorDeal.discountValue + "%"}
                </span>
              </div>
              {campaigns.operatorDeal.discountType == "ABSOLUTE" ? (
                <div className="finalData">
                  <span className="subHeading">Min. Offer per seat : ₹ </span>
                  <span className="subHeading">
                    {campaigns.operatorDeal.minSeatValue}
                  </span>
                </div>
              ) : (
                <div className="minMaxDiscount">
                  <div className="finalData">
                    <span className="subHeading">Min. Offer per seat : ₹ </span>
                    <span className="subHeading">
                      {campaigns.operatorDeal.minSeatValue}
                    </span>
                  </div>

                  <div className="finalData">
                    <span className="subHeading">Max. Offer per seat : ₹ </span>
                    <span className="subHeading">
                      {campaigns.operatorDeal.maxDiscount}
                    </span>
                  </div>
                </div>
              )}

              <div className="finalData">
                <span className="subHeading">Start Date : </span>
                <span className="subHeading">
                  {moment(campaigns.startDate).format("DD/MM/YY")}{" "}
                </span>
              </div>
              <div className="finalData">
                <span className="subHeading">End Date : </span>
                <span className="subHeading">
                  {moment(campaigns.endDate).format("DD/MM/YY")}
                </span>
              </div>
              <div className="finalData">
                <span className="subHeading">Active Days: </span>
                <span className="subHeading">
                  {campaigns.applicableDays.includes("MON") ? (
                    <span className="days">M</span>
                  ) : null}
                  {campaigns.applicableDays.includes("TUE") ? (
                    <span className="days">T</span>
                  ) : null}
                  {campaigns.applicableDays.includes("WED") ? (
                    <span className="days">W</span>
                  ) : null}
                  {campaigns.applicableDays.includes("THU") ? (
                    <span className="days">T</span>
                  ) : null}
                  {campaigns.applicableDays.includes("FRI") ? (
                    <span className="days">F</span>
                  ) : null}
                  {campaigns.applicableDays.includes("SAT") ? (
                    <span className="days">S</span>
                  ) : null}
                  {campaigns.applicableDays.includes("SUN") ? (
                    <span className="days">S</span>
                  ) : null}
                </span>
              </div>
              <div className="finalData">
                <span className="subHeading">Exclusion Date: </span>
                <span className="subHeading">{campaigns.date}</span>
              </div>
            </div>
            {campaigns.operatorDeal.type == "EARLYBIRD" ? (
              <p className="finalDisclaminer">
                Deactivate when{" "}
                <b>{campaigns.operatorDeal.stopDealAtSeatCount.gte} seats</b>{" "}
                occupied or{" "}
                <b>
                  {Math.floor(
                    getHoursAndMinFromMillis(
                      campaigns.operatorDeal.stopDealTime,
                      true
                    )
                  )}{" "}
                  days
                </b>{" "}
                before departure
              </p>
            ) : campaigns.operatorDeal.type == "LASTMINUTE" ? (
              <p className="finalDisclaminer">
                Offer starts{" "}
                <b>
                  {getHoursAndMinFromMillis(
                    campaigns.operatorDeal.startDealTime,
                    false,
                    true
                  )}{" "}
                  hrs{" "}
                </b>
                before departure if occupancy is more than{" "}
                <b>{campaigns.operatorDeal.startDealAtOccupancy.lte}%</b> and
                offer is valid till occupancy becomes{" "}
                <b>{campaigns.operatorDeal.stopDealAtOccupancy.gte}%</b>
              </p>
            ) : null}

            <div>
              <div className="dealSpan allServices">
                <p>Sevices</p>
              </div>
              {Array.isArray(campaigns.serviceName)
                ? campaigns.serviceName.map((obj) => {
                    return (
                      <>
                        <div className="checkBox">
                          <p>
                            {obj.fromCityName} to {obj.toCityName}
                          </p>
                          <p>{getHoursAndMinFromMillis(obj.startTime)}</p>
                        </div>
                        <ConfigProvider
                          theme={{
                            token: {
                              marginLG: 0,
                            },
                          }}
                        >
                          <Divider />
                        </ConfigProvider>
                      </>
                    );
                  })
                : null}
            </div>
            <Modal
              centered
              open={showConfirmationModal}
              onCancel={this.closeModal}
              cancelButtonProps={{ style: { display: "none" } }}
              okButtonProps={{ style: { display: "none" } }}
            >
              <div className="modalDiv">
                <img
                  src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deal/warning.svg"
                  alt="warning"
                />
                <p className="modalHeader">
                  Are you sure that you want to turn{" "}
                  {campaigns.status == "ACTIVE" ? "off" : "on"} this campaign
                </p>
                <button onClick={this.updateCampaign} className="primary">
                  Yes
                </button>
                <p className="cancel" onClick={this.closeModal}>
                  No
                </p>
              </div>
            </Modal>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="DealsHeader">
            <div className="headerDetails">
              <img
                src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deal/back.svg"
                alt="back"
                onClick={() => {
                  this.backPressed();
                }}
              />
              <span className="dealSpan">Deal Details </span>
            </div>
          </div>
          <div className="containerPadding">
            <p className="dealSpan">Final Campaign Details</p>

            <div className="left">
              <div className="details">
                <div className="finalData">
                  <span className="subHeading">Campaign Type : </span>
                  <span className="subHeading">
                    {campaigns.operatorDeal.type}
                  </span>
                </div>
                <div className="finalData">
                  <span className="subHeading">Discount Type : </span>
                  <span className="subHeading">
                    {campaigns.operatorDeal.discountType == "ABSOLUTE"
                      ? "FLAT"
                      : campaigns.operatorDeal.discountType}
                  </span>
                </div>

                <div className="finalData">
                  <span className="subHeading">
                    {campaigns.operatorDeal.discountType == "ABSOLUTE"
                      ? "Discount Amount : ₹"
                      : "Discount Percentage : "}{" "}
                  </span>
                  <span className="subHeading">
                    {campaigns.operatorDeal.discountType == "ABSOLUTE"
                      ? campaigns.operatorDeal.discountValue
                      : campaigns.operatorDeal.discountValue + "%"}
                  </span>
                </div>
                <div className="finalData">
                  <span className="subHeading">Min. Offer per seat : ₹ </span>
                  <span className="subHeading">
                    {campaigns.operatorDeal.minSeatValue}
                  </span>
                </div>
                {campaigns.operatorDeal.discountType == "ABSOLUTE" ? null : (
                  <div className="finalData">
                    <span className="subHeading">Max. Offer per seat : ₹ </span>
                    <span className="subHeading">
                      {campaigns.operatorDeal.maxDiscount}
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="right">
              <div className="details">
                <div className="finalData">
                  <span className="subHeading">Start Date : </span>
                  <span className="subHeading">
                    {moment(campaigns.startDate).format("LLL")}{" "}
                  </span>
                </div>
                <div className="finalData">
                  <span className="subHeading">End Date : </span>
                  <span className="subHeading">
                    {" "}
                    {moment(campaigns.endDate).format("LLL")}
                  </span>
                </div>
                <div className="finalData">
                  <span className="subHeading">Active Days: </span>
                  <span className="subHeading">
                    {campaigns.applicableDays.includes("MON") ? (
                      <span className="days">M</span>
                    ) : null}
                    {campaigns.applicableDays.includes("TUE") ? (
                      <span className="days">T</span>
                    ) : null}
                    {campaigns.applicableDays.includes("WED") ? (
                      <span className="days">W</span>
                    ) : null}
                    {campaigns.applicableDays.includes("THU") ? (
                      <span className="days">T</span>
                    ) : null}
                    {campaigns.applicableDays.includes("FRI") ? (
                      <span className="days">F</span>
                    ) : null}
                    {campaigns.applicableDays.includes("SAT") ? (
                      <span className="days">S</span>
                    ) : null}
                    {campaigns.applicableDays.includes("SUN") ? (
                      <span className="days">S</span>
                    ) : null}
                  </span>
                </div>
                <div className="finalData">
                  <span className="subHeading">Exclusion Date: </span>
                  <span className="subHeading">{campaigns.date}</span>
                </div>
              </div>
            </div>

            <br />
            {campaigns.operatorDeal.type == "EARLYBIRD" ? (
              <p className="finalDisclaminer">
                Deactivate when{" "}
                <b>{campaigns.operatorDeal.stopDealAtSeatCount.gte} seats</b>{" "}
                occupied or{" "}
                <b>
                  {Math.floor(
                    getHoursAndMinFromMillis(
                      campaigns.operatorDeal.stopDealTime,
                      true
                    )
                  )}{" "}
                  days
                </b>{" "}
                before departure
              </p>
            ) : campaigns.operatorDeal.type == "LASTMINUTE" ? (
              <p className="finalDisclaminer">
                Offer starts{" "}
                <b>
                  {getHoursAndMinFromMillis(
                    campaigns.operatorDeal.startDealTime,
                    false,
                    true
                  )}{" "}
                  hrs{" "}
                </b>
                before departure if occupancy is more than{" "}
                <b>{campaigns.operatorDeal.startDealAtOccupancy.lte}%</b> and
                offer is valid till occupancy becomes{" "}
                <b>{campaigns.operatorDeal.stopDealAtOccupancy.gte}%</b>
              </p>
            ) : null}
            <div className="serviceInfo">
              <div className="dealSpan allServices">
                <p>Services</p>
              </div>
              <Table
                columns={columns}
                bordered={true}
                dataSource={campaigns.serviceTable}
                pagination={false}
              />
            </div>
          </div>
        </>
      );
    }
  }
}

export default OperatorDealsDetails;
